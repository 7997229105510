import React from "react";
import UriClickableComponent from "Includes/UriClickableComponent.js";
import {Button as ButtonMui, CircularProgress, withStyles} from "@material-ui/core";

/**
 * Button component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Button extends UriClickableComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<ButtonMui
				className={this.props.className}
				classes={this.props.classes}
				color={(this.props.color || "primary")}
				disabled={(this.props.disabled || this.props.loading)}
				onClick={this.handleClick}
				size={this.props.size}
				startIcon={((this.props.loading && this.props.startIcon) ? null : this.props.startIcon)}
				style={{borderRadius: (this.props.squared ? 0 : null), ...this.props.style}}
				type={this.props.type}
				variant={this.props.variant}
				value={this.props.value}>
				{(this.props.loading && this.renderLoader())}
				{this.props.label}
			</ButtonMui>
		);
	}


	/**
	 * Render the loader.
	 * 
	 * @return {ReactNode}
	 */
	renderLoader() {
		return (
			<CircularProgress
				color="secondary"
				disableShrink={true}
				size={18}
				style={this.loaderStyles} />
		);
	}


	/**
	 * Loader styles.
	 * 
	 * @return {Object}
	 */
	get loaderStyles() {
		return {
			margin: (!this.props.label ? "0 auto !important" : undefined),
			marginLeft: (this.props.label ? "-0.2rem" : undefined),
			marginRight: (this.props.label ? "0.8rem" : undefined)
		};
	}


	static styles = {
		startIcon: {
			flexShrink: 0
		}
	};

}

export default withStyles(Button.styles)(Button);
