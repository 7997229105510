/**
 * @author HOPS
 * @copyright Heritage Operations Processing Limited
 */

/*
 * the locale language, used to format the order of date and time components in the locale specified
 * use these functions to keep locale consistent
 */
const locale = "en-GB"; // adhere to UK locale

/**
 * Convert date string/object to date string with custom date options, with spaces between values
 * @param {string|object} date
 * @param {object|null} options e.g. {weekday: "long", day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"}
 * @returns {string} formatted date
 */
export const formatLocaleDateString = (date, options) => {
	if (!date) {
		return "";
	}
	date = new Date(date);
	return date.toLocaleDateString(locale, options).replace(",", " ");
};

/**
 * Convert date string/object to date string
 * @param {string|object} date
 * @returns {string} e.g. Tuesday 6 April 2021
 */
export const formatLongDate = date => {
	return formatLocaleDateString(date, {
		weekday: "long",
		day: "numeric",
		month: "long",
		year: "numeric"
	});
};

/**
 * Convert date string/object to date string
 * @param {string|object} date
 * @returns {string} e.g. Tue 06 Apr 2021
 */
export const formatDateTimetable = date => {
	return formatLocaleDateString(date, {
		weekday: "short",
		day: "2-digit",
		month: "short",
		year: "numeric"
	});
};

/**
 * Convert date string/object to short date string
 * @param {string|object} date
 * @returns {string} e.g. 24/03/2021
 */
export const formatShortDate = date => {
	return formatLocaleDateString(date, {
		day: "2-digit",
		month: "2-digit",
		year: "numeric"
	});
};

/**
 * Convert date string/object to date and time string
 * @param {string|object} date
 * @returns {string} e.g. Tuesday 6 April 2021
 */
export const formatShortDateTime = date => {
	return formatLocaleDateString(date, {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit"
	});
};

/**
 * Convert start date and end date range to date string
 * If the years are different, the year will be included in the start date
 * @param {string|object} startDate
 * @param {string|object} endDate
 * @returns {string} e.g. Wednesday 1 November - Thursday 30 November 2023
 */
export const formatLongDateRange = (startDate, endDate) => {
	const sDate = new Date(startDate);
	const eDate = new Date(endDate);
	const sameYear = sDate.getFullYear() === eDate.getFullYear();

	const sDateFormatted = sameYear ?
		formatLocaleDateString(sDate, {
			weekday: "long",
			day: "numeric",
			month: "long"
		}) :
		formatLongDate(sDate);
	const eDateFormatted = formatLongDate(eDate);

	return `${sDateFormatted} - ${eDateFormatted}`;
};

/**
 * Convert milliseconds to date.
 * @param {number} milliseconds
 * @returns {string} e.g. Tuesday 6 April 2021
 */
export const millisecondsToDate = milliseconds => new Date(milliseconds * 1000);

/**
 * Convert number or string to date object.
 * @param {number|string} o 
 * @returns {object|null} date
 */
export const objectToDate = o => {
	if (!o) {
		return null;
	}
	else if (typeof o === "number") {
		return millisecondsToDate(o);
	}
	else {
		return new Date(o);
	}
};

