import AccountInfoRefreshTask from "Tasks/AccountInfoRefreshTask.js";
import AccountInfoAdminRefreshTask from "Tasks/AccountInfoAdminRefreshTask.js";
import AuthPingTask from "Tasks/AuthPingTask.js";
import CheckAppUpdateTask from "Tasks/CheckAppUpdateTask.js";
import CheckAuthExpirationTask from "Tasks/CheckAuthExpirationTask.js";
import CheckChatsTask from "Tasks/CheckChatsTask.js";
import CheckHopsNoticesTask from "Tasks/CheckHopsNoticesTask.js";
import CheckNotificationsTask from "Tasks/CheckNotificationsTask.js";
import CheckOrgRevokedTask from "Tasks/CheckOrgRevokedTask.js";
import CheckOrgSelectedTask from "Tasks/CheckOrgSelectedTask.js";
import RefreshUserFunctionsTask from "Tasks/RefreshUserFunctionsTask.js";
import RefreshUserPermissionsTask from "Tasks/RefreshUserPermissionsTask.js";

export default [
	{
		id: "CheckAuthExpiration",
		frequency: 60,
		requireAuth: true,
		task: CheckAuthExpirationTask
	},
	{
		id: "CheckAuthAdminExpiration",
		frequency: 60,
		requireAuth: true,
		requireAuthAdmin: true,
		task: CheckAuthExpirationTask,
		taskParams: [true, true]
	},
	{
		id: "CheckOrgSelected",
		frequency: 60,
		requireAuth: true,
		task: CheckOrgSelectedTask
	},
	{
		id: "AuthPing",
		frequency: 900,
		requireAuth: true,
		requireOnline: true,
		task: AuthPingTask
	},
	{
		id: "AccountInfoRefresh",
		frequency: 900,
		requireAuth: true,
		requireOnline: true,
		task: AccountInfoRefreshTask
	},
	{
		id: "AccountInfoAdminRefresh",
		frequency: 900,
		requireAuth: true,
		requireAuthAdmin: true,
		requireOnline: true,
		task: AccountInfoAdminRefreshTask
	},
	{
		id: "CheckOrgRevoked",
		frequency: 900,
		requireAuth: true,
		requireOnline: true,
		task: CheckOrgRevokedTask
	},
	{
		id: "RefreshUserPermissions",
		frequency: 900,
		requireAuth: true,
		requireOnline: true,
		task: RefreshUserPermissionsTask
	},
	{
		id: "RefreshUserFunctions",
		frequency: 900,
		requireAuth: true,
		requireOnline: true,
		task: RefreshUserFunctionsTask
	},
	{
		id: "CheckHopsNotices",
		frequency: 900,
		requireOnline: true,
		task: CheckHopsNoticesTask
	},
	{
		id: "CheckNotifications",
		frequency: 60,
		requireAuth: true,
		requireOnline: true,
		task: CheckNotificationsTask
	},
	{
		id: "CheckChats",
		frequency: 60,
		requireAuth: true,
		requireOnline: true,
		task: CheckChatsTask
	},
	{
		id: "CheckAppUpdateTask",
		frequency: 900,
		requireOnline: true,
		task: CheckAppUpdateTask
	}
];
