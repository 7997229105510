/* eslint-disable no-console */
import React from "react";
import Bghost from "App/Bghost.js";
import ChatPane from "Chat/ChatPane.js";
import Container from "Components/Container.js";
import DepartmentMenu from "Departments/DepartmentMenu/DepartmentMenu.js";
import Document from "Helpers/Document.js";
import ErrorGate from "Components/ErrorGate.js";
import F12 from "F12/F12.js";
import Flex from "Components/Flex.js";
import HopsNotices from "NoticesHops/HopsNotices.js";
import KioskMode from "./KioskMode.js";
import Logger from "Includes/Logger.js";
import LogoutDialog from "Login/LogoutDialog.js";
import MainDenied from "./MainDenied.js";
import MainLoader from "./MainLoader.js";
import MainLogin from "./MainLogin.js";
import Navigator from "App/Navigator.js";
import NotificationPane from "Notifications/NotificationPane.js";
import RaildaysUiControls from "Ui/Raildays/RaildaysUiControls.js";
import RosterMenu from "Rosters/RosterMenu/RosterMenu.js";
import Router from "./Router.js";
import Styles from "Resources/Styles.json";
import SuspenseGate from "./SuspenseGate.js";
import Tasker from "App/Tasker.js";
import UiBar from "Ui/UiBar.js";
import UiDrawer from "Ui/UiDrawer.js";
import Updated from "Ui/Updated.js";
import UpdateAvailable from "Ui/UpdateAvailable.js";
import UpdateHopsCookies from "Tasks/UpdateHopsCookiesTask.js";
import dAccess from "Dispatchers/dAccess.js";
import dAppv from "Dispatchers/dAppv.js";
import dChats from "Dispatchers/dChats.js";
import dLogoutDialogClose from "Dispatchers/dLogoutDialogClose.js";
import dNoticesHopsClearOrg from "Dispatchers/dNoticesHopsClearOrg.js";
import dNotifications from "Dispatchers/dNotifications.js";
import dPermissions from "Dispatchers/dPermissions.js";
import dRaildaysActiveCard from "Dispatchers/dRaildaysActiveCard.js";
import dUiDrawerClose from "Dispatchers/dUiDrawerClose.js";
import dUserFunctions from "Dispatchers/dUserFunctions.js";
import withAuth from "Hoc/withAuth.js";
import withMobile from "Hoc/withMobile.js";
import withRaildays from "Hoc/withRaildays.js";
import withSnack from "Hoc/withSnack.js";
import qs from "query-string";
import scss from "./Main.module.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";

/**
 * Main component
 * 
 * Application-level component rendering the main UI.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Main extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Ready to render?
			 * 
			 * @type {Boolean}
			 */
			ready: false

		};

		/**
		 * Method binds
		 */
		this.runTasks = () => Tasker.run(true);

		/**
		 * Event listener for network online
		 */
		this.online = null;

	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	async componentDidMount() {

		/**
		 * App version dispatch
		 */
		dAppv();

		/**
		 * Update all cookies
		 */
		UpdateHopsCookies();

		/**
		 * Set Senty user
		 */
		if (this.props.authed) {
			this.setSentryUser();
		}

		/**
		 * We don't want trailing `/`
		 */
		this.stripUriSlashes();

		/**
		 * Initialise the `<title>` tag
		 */
		this.setDocumentTitle();

		/**
		 * Begin logging
		 */
		Logger.log("App initialising...");

		/**
		 * Run all tasks
		 */
		Logger.log("Running tasks...");
		await Tasker.run();
		Logger.log("Tasks complete.");

		/**
		 * We're ready!
		 */
		this.setState({ready: true});
		Logger.log("Ready now.");

		/**
		 * We can enable cancellation of redundant tasks now
		 *
		 * We can't do this until we're fully initialised in case 
		 * any initialisation routines call things like `dReset()` 
		 * which would cancel the active task series, causing 
		 * `Tasker.run()` above to never resolve...
		 */
		Bghost.BGHOST_ACTIVE = true;

		/**
		 * Online event listener
		 */
		window.addEventListener("online", this.runTasks);

	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		Tasker.destroy();
		window.removeEventListener("online", this.runTasks);
	}


	/**
	 * Component updated.
	 * 
	 * We may need to handle changes.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		/**
		 * Changes
		 */
		const mobileChanged = this.mobileChanged(prevProps);
		const routeChanged = this.routeChanged(prevProps);

		/**
		 * When authentication changes, we align all the tasks
		 *
		 * This to prevent an unnecessary run of *all* tasks occurring 
		 * on first task run after login if the user was not logged when 
		 * we launched, due to Tasker then seeing all tasks as due.
		 */
		if (prevProps.authed !== this.props.authed) {
			Tasker.alignAllTasks();
		}

		/**
		 * Close the drawer
		 */
		if (routeChanged || mobileChanged) {
			dUiDrawerClose();
		}

		/**
		 * Strip trailing URI slash
		 */
		if (routeChanged) {

			/**
			 * Route updates
			 */
			this.stripUriSlashes();

			/**
			 * Remove any 403
			 */
			if (prevProps.Access === this.props.Access) {
				dAccess(true);
			}

		}

		/**
		 * Organisation changed
		 */
		if (this.props.org?.Id !== prevProps.org?.Id) {
			dAccess(true);
		}

		/**
		 * Set the document title
		 */
		if (this.props.Route !== prevProps.Route) {

			/**
			 * Document title
			 */
			this.setDocumentTitle();

			/**
			 * Login target URI etc.
			 */
			const qsuri = qs.parse(this.props.location.search).uri;
			if (qsuri && !this.props.Route?.disable_uri_target_override) {
				this.props.history.replace(qsuri);
			}

		}

		/**
		 * Check whether auth changed
		 */
		const authChange = (prevProps.authed !== this.props.authed);
		const authAdminChange = (prevProps.authAdmin?.account?.Id !== this.props.authAdmin?.account?.Id);
		const orgChange = (prevProps.org?.Id !== this.props.org?.Id);
		const stateChange = (authChange || authAdminChange || orgChange);

		/**
		 * Sentry user update
		 */
		if (authChange) {
			this.setSentryUser();
		}

		/**
		 * Admin login state changed
		 */
		if (authAdminChange) {

			/**
			 * Rseet the selected Raildays ID card to the first available
			 */
			dRaildaysActiveCard(0);

		}

		/**
		 * We need to refresh core state
		 */
		if (stateChange) {

			/**
			 * Clear `ui`-series network requests
			 *
			 * (We want to be sure the right data ends up in state 
			 * so we do a new task run below for our current state.)
			 */
			Bghost.clearSeries("ui");

			/**
			 * Resets
			 */
			dUserFunctions(null);
			dNoticesHopsClearOrg();
			dNotifications([]);
			dPermissions(null);
			dChats(null);

			/**
			 * Task exclusions
			 */
			let taskExclusions = [];

			/**
			 * We have logged in; skip auth refresh tasks
			 */
			if (authChange && (this.props.authed && !prevProps.authed)) {
				taskExclusions = [
					"CheckAuthExpiration",
					"CheckAuthAdminExpiration",
					"AuthPing",
					"AccountInfoRefresh",
					"AccountInfoAdminRefresh",
					"CheckOrgRevoked",
					"CheckAppUpdateTask"
				];
			}

			/**
			 * This is safe
			 *
			 * We normally prevent starting a new task run if one's 
			 * already active - however, from the above, we are 
			 * excluding all tasks except those that make network 
			 * calls in the Bghost-managed `ui` series, and we've 
			 * just aborted any old requests in that series.
			 *
			 * We need to run tasks NOW to address edge case scenarios 
			 * observed 28/10/2021 where e.g. changing org/admin-logging-in 
			 * then very quickly changing to a different org/exiting admin 
			 * login, while the tasks were still running, resulted in 
			 * the `ui` series cancelling, but no new task run being 
			 * accepted, so the UI got stuck in a permanent loading state.
			 *
			 * In the future `Bghost` and `ui` should be combined really 
			 * to make it clearer what's going on in the above.
			 */
			Tasker.running = false;
			Tasker.run(true, taskExclusions);

		}

		/**
		 * Redirect to homescreen if org changed / admin login changed
		 *
		 * (We check `this.props.org` to make sure we're stil logged in 
		 *  as otherwise we'd go to home whenever auth expired.)
		 */
		if ((orgChange && this.props.org && prevProps.authed) || authAdminChange) {
			Navigator.navigate((this.props.OrgSwitchTargetUri || "/"));
		}

	}


	/**
	 * Set the `document` title using our current route.
	 * 
	 * @return {void}
	 */
	setDocumentTitle() {
		if (this.props.Route && this.props.Route?.title) {
			Document.setTitle(this.props.Route?.title);
		}
	}


	/**
	 * Strip excess slashes from our URI.
	 * 
	 * @return {void}
	 */
	stripUriSlashes() {
		if (!["", "/"].includes(this.props.location.pathname)) {
			const pathname = this.props.location.pathname.replace(/(\/+)/, "/").replace(/(\/+)$/, "");
			if (pathname !== this.props.location.pathname) {
				const query = this.props.location.search;
				this.props.history.replace(`${pathname}${query}`);
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (this.state.ready ? this.renderMain() : <MainLoader viewport={true} />);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<React.Fragment>
				{(!this.useRaildaysRendering && <UiBar />)}
				{((this.props.authed && !this.props.isAdminAuthed) && <ChatPane />)}
				{(this.props.authed && <NotificationPane />)}
				<Container
					className={scss.mainContainer}
					columnar={true}
					gap={(this.drawerInvisible ? 0 : 1)}
					style={this.containerStyles}>
					<Flex
						className={scss.side}
						py={1}
						style={this.constructor.sidebarContainerStyles}>
						{(this.doRenderLogin ? <MainLogin /> : null)}
						{(this.doRenderDrawer ? <UiDrawer /> : null)}
					</Flex>
					<Flex
						className={scss.mainContainerInner}
						pl={(this.useMainPaddingLeft ? 1 : 0)}
						pr={1}
						py={1}
						style={this.mainContainerStyles}>
						{((this.props.UpdateAvailable && this.props.authed) && <UpdateAvailable />)}
						{(!this.props.location.pathname.startsWith("/raildays") && <HopsNotices />)}
						<Sentry.ErrorBoundary
							fallback={<ErrorGate noPadding={true} />}
							key={this.props.HistoryTimestamp}>
							{this.renderMainContent()}
						</Sentry.ErrorBoundary>
					</Flex>
				</Container>
				<LogoutDialog
					onClose={dLogoutDialogClose}
					open={this.props.LogoutDialog} />
				<F12 />
				<KioskMode />
				<Updated />
				{(this.props.authed && this.props.raildays && !window.location.pathname.startsWith("/raildays") && <RaildaysUiControls />)}
			</React.Fragment>
		);
	}


	/**
	 * Render main.
	 *
	 * @return {ReactNode}
	 */
	renderMainContent() {
		if (this.props.authed && !this.props.permissions) {
			return <MainLoader />;
		}
		else if (this.props.Access && !this.isForbiddenRoute) {
			return (
				<SuspenseGate>
					<React.Suspense fallback={<MainLoader />}>
						{this.props.authed &&
							<>
								<DepartmentMenu />
								<RosterMenu />
							</>
						}
						<Router key={this.props.HistoryTimestamp} />
					</React.Suspense>
				</SuspenseGate>
			);
		}
		else return <MainDenied />;
	}


	/**
	 * Get whether we've changed to/from mobile viewport since a "previous" 
	 * props object, as given by `isMobile` prop from the `withMobile` HOC.
	 *
	 * @param {Object} prevProps
	 * @return {Boolean}
	 */
	mobileChanged(prevProps) {
		return (prevProps?.isMobile !== this.props.isMobile);
	}


	/**
	 * Get whether the route has changed from a "previous" props object.
	 * 
	 * @param {Object} prevProps
	 * @return {Boolean}
	 */
	routeChanged(prevProps) {
		const pathname = prevProps?.location?.pathname;
		const search = prevProps?.location?.search;
		const uri = (pathname !== this.props.location.pathname);
		const qs = (search !== this.props.location.search);
		return (uri || qs);
	}


	/**
	 * Set the Sentry user.
	 * 
	 * @return {void}
	 */
	setSentryUser() {
		if (!this.props.authed) Sentry.setUser(null);
		else Sentry.setUser({username: this.props.userAccountReal.Username});
	}


	/**
	 * Container `grid-template-columns`.
	 *
	 * @return {String} CSS
	 */
	get containerColumns() {
		if (this.doRenderLogin) return `${Styles.loginPaneWidth} auto`;
		else if (this.drawerInvisible) return "0 100%";
		else return `${Styles.uiDrawerWidth} calc(100% - ${Styles.uiDrawerWidth} - 1.5rem)`; // fixed width otherwise flex items can overflow parent
	}


	/**
	 * Container styles.
	 * 
	 * @return {Object}
	 */
	get containerStyles() {
		return {
			gridTemplateColumns: this.containerColumns,
			gridTemplateRows: "100%",
			minHeight: `100%`
		};
	}


	/**
	 * Get whether to render the drawer.
	 * 
	 * @return {Boolean}
	 */
	get doRenderDrawer() {
		return (this.props.authed && !this.useRaildaysRendering);
	}


	/**
	 * Get whether to render the login form.
	 * 
	 * @return {Boolean}
	 */
	get doRenderLogin() {
		const wanted = (!this.props.authed && !this.props.Route?.noLogin);
		return (!this.props.isMobile ? wanted : false);
	}


	/**
	 * Get whether the drawer should be invisible.
	 * 
	 * @return {Boolean}
	 */
	get drawerInvisible() {
		return (!this.props.authed || this.props.isMobile || this.props.KioskMode || this.useRaildaysRendering);
	}


	/**
	 * Get whether the current route is "forbidden" to render for the user.
	 * 
	 * @return {Boolean}
	 */
	get isForbiddenRoute() {
		if (!this.props.Route) return false;
		else if (!this.props.Route.hasOwnProperty("auth")) return false;
		else return (this.props.Route.auth !== this.props.authed);
	}


	/**
	 * Get whether to apply left padding to the main view.
	 * 
	 * @return {Boolean}
	 */
	get useMainPaddingLeft() {
		const login = this.doRenderLogin;
		return ((this.props.isMobile || this.drawerInvisible) && !login);
	}


	/**
	 * Get whether to use Raildays rendering mode.
	 *
	 * @return {Boolean}
	 */
	get useRaildaysRendering() {
		return (this.props.authed && this.props.raildays && window.location.pathname.startsWith("/raildays"));
	}


	/**
	 * Main container styles.
	 * 
	 * @return {Object}
	 */
	get mainContainerStyles() {
		return {
			marginTop: (!this.useRaildaysRendering ? Styles.uiBarHeight : undefined)
		};
	}


	/**
	 * Sidebar container styles.
	 * 
	 * @type {Object}
	 */
	static sidebarContainerStyles = {
		display: "block",
		gridTemplateRows: "auto",
		marginTop: Styles.uiBarHeight
	};


	/**
	 * Map state to props.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {
			Access: state.Access,
			HistoryTimestamp: state.HistoryTimestamp,
			KioskMode: state.KioskMode,
			LogoutDialog: state.LogoutDialog,
			OrgSwitchTargetUri: state.OrgSwitchTargetUri,
			Route: state.Route,
			UpdateAvailable: state.UpdateAvailable,
			org: state.org
		};
	}

}

export default connect(Main.mapStateToProps)(withAuth(withMobile(withRaildays(withRouter(withSnack(Main))))));
